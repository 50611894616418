'use strict';

/* Imports */
import $ from 'jquery';
window.$ = window.jQuery = $; // this executes after imports
import objectFitImages from 'object-fit-images';
import Swiper, { Navigation } from 'swiper';
// import gsap from 'gsap';
// import barba from '@barba/core';
import {concat, isEmpty, join} from 'lodash';
import { Dropdown } from 'bootstrap';
require('./playlists');
require('selectric');
require('bootstrap-datepicker');
require('./jquery.popupsdoneright');
require('./html5audio');

/**
 * Polyfill object-fit/object-position on <img>
 */
function initObjectFitImages() {
	let objFitImages = $('[objectFit-js]') || document.querySelectorAll('[objectFit-js]');
	objectFitImages(objFitImages);
}

/**
 * Initialize Swiper in JS.
 */
function initSwiper() {
	new Swiper('.swiper-container', {
		modules: [Navigation],
		// Optional parameters
		wrapperClass: 'swiper-wrapper',
		slideClass: 'swiper-slide',
		watchOverflow: true,
		normalizeSlideIndex: true,
		grabCursor: false,
		freeMode: false,
		touchMoveStopPropagation: false,
		simulateTouch: false,
		allowSwipeToNext: true,
		allowSwipeToPrev: true,
		allowPageScroll: 'auto ',
		// direction: 'horizontal', // 'horizontal' or 'vertical'
		loop: false,
		effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip"
		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},

		// autoplay: {
		//   delay: 5000,
		// },
		// Disable preloading of all images
		// preloadImages: false,
		// Enable lazy loading
		// lazy: {
		//   loadPrevNext: true,
		// },
		slidesPerView: 3,
		// slidesPerGroup: 4,
		spaceBetween: 0,
		// width: 192,
		breakpoints: {
			// when window width is >= 320px
			50: {
				slidesPerView: 1,
				spaceBetween: 10
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 2,
				spaceBetween: 0
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 3,
				spaceBetween: 0
			},
			// when window width is >= 640px
			992: {
				slidesPerView: 4,
				spaceBetween: 0
			}
		},

		// on: {
		//   "slideChange": function () {
		// console.log("slideChange");
		// },
		// }
	});
}
function initSwiper2() {
	new Swiper('.swiper', {
		// Optional parameters
		wrapperClass: 'swiper-wrapper',
		slideClass: 'swiper-slide',
		watchOverflow: true,
		normalizeSlideIndex: true,
		grabCursor: false,
		freeMode: false,
		touchMoveStopPropagation: false,
		simulateTouch: false,
		allowSwipeToNext: true,
		allowSwipeToPrev: true,
		allowPageScroll: 'auto ',
		// direction: 'horizontal', // 'horizontal' or 'vertical'
		loop: true,
		effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip"
		// autoplay: {
		//   delay: 5000,
		// },
		// Disable preloading of all images
		// preloadImages: false,
		// Enable lazy loading
		// lazy: {
		//   loadPrevNext: true,
		// },
		slidesPerView: 1,
		// spaceBetween: 0,
		// width: 192,
		// breakpoints: {
		// when window width is <= 320px
		// 320: {
		// slidesPerView: 1,
		// spaceBetween: 10
		// },
		// when window width is <= 480px
		// 480: {
		// slidesPerView: 2,
		// spaceBetween: 20
		// },
		// when window width is <= 640px
		// 640: {
		// slidesPerView: 3,
		// spaceBetween: 30
		// }
		// },

		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}

		// on: {
		//   "slideChange": function () {
		// console.log("slideChange");
		// },
		// }
	});
}

/*barba.init({
	debug: true,    // todo for debugging
	timeout: 15000, // for debugging
	prefetchIgnore: true,
	transitions: [{
		name: 'opacity-transition',
		// sync: true,
		leave(data) {
			return gsap.to(data.current.container, {
				opacity: 0
			});
		},
		enter(data) {
			return gsap.from(data.next.container, {
				opacity: 0
			});
		},
		afterEnter() {
			// re-init/reset script default function on before page load
			initElements();
		}
	}]
});*/

/**
 * Document DOM ready.
 */
$(document).ready(function () {
	initElements();
});

function initElements () {
	/**
	 *
	 * @type {*|jQuery|HTMLElement}
	 * @private
	 */
	let _document = $(document),
		_window   = $(window);

	/**
	 * Execute initialization methods
	 */
	initSwiper();
	initObjectFitImages();
	closeMobileMenu();

	/**
   * Prevent default on all empty links
   */
	_document.on('click', '[href="#"]', function (e) {
		e.preventDefault();
	});

	/**
   * Click on body: Close all open menus
   */
	$('body').on('click', function (e) {
		let className = '.swiper-slide-menu, .swiper-slide-drop, .search__label, .mainr__search-label, .search__keyboard';

		if (!$(e.target).closest(className).length) {
			$('[featured-menu-js]').removeClass('is-show');
			$('.swiper-slide-drop').removeClass('is-open');

			$('[search-keyboard-js]').removeClass('is-active');
			$('.search__keyboard').fadeOut(300);
		}
	});

	/**
   * Main category navigation (for mobile)
   */
	$('[menu-item-js]').on('click', function (e) {
		if (e.target !== this && e.target.parentElement !== this) { return; }
		let elem = $(e.currentTarget);

		if (_window.width() < 992) {
			if (elem.hasClass('is-active')) {
				elem.find('.nav__link-drop').slideUp(300);
			} else {
				e.preventDefault();
				$('.nav__link-drop').hide();
				elem.find('.nav__link-drop').slideDown(300);
			}
		}

		if (elem.hasClass('is-active')) {
			console.log('if btn');
			elem.removeClass('is-active');
		} else {
			console.log('else btn');
			$('[menu-item-js]').removeClass('is-active');
			elem.addClass('is-active');
		}
	});

	/**
   * Home page: featured shiur menu buttons
   */
	$('[featured-menu-js]').on('click', function (e) {
		e.preventDefault();

		let elem = $(e.currentTarget),
			elemAttrId = elem.attr('data-id');

		if (elem.hasClass('is-show')) {
			elem.removeClass('is-show');
			$(".swiper-slide-drop[data-drop='" + elemAttrId + "']").removeClass('is-open');
		} else {

			$('[featured-menu-js]').removeClass('is-show');
			$('.swiper-slide-drop').removeClass('is-open');

			elem.addClass('is-show');
			$(".swiper-slide-drop[data-drop='" + elemAttrId + "']").addClass('is-open');
		}
	});

	/**
   * Hamburger button for mobile navigation
   */
	$('[hamburger-btn-js]').on('click', function () {
		let mobileMenuContainer = $('.header__middle'),
			hideScrollElem = $('html, body');

		$('[hamburger-btn-js]').toggleClass('is-active');
		mobileMenuContainer.toggleClass('is-show');
		if (_window.width() < 778) {
			hideScrollElem.toggleClass('is-hideScroll');
		}
	});
	function closeMobileMenu() {
		$('.header__middle-wrap').on('click', function () {
			let hamburgerBtn = $('[hamburger-btn-js]'),
				mobileMenuContainer = $('.header__middle'),
				hideScrollElem = $('html, body');

			hamburgerBtn.removeClass('is-active');
			mobileMenuContainer.removeClass('is-show');
			hideScrollElem.removeClass('is-hideScroll');
		});
	}

	/**
   * Collapsable table for mobile (shiurim list in player)
   */
	$('[table-head-js]').on('click', function (e) {
		let elem = $(e.currentTarget),
			bodyContainer = elem.closest('.table__row').find('[table-body-js]');

		if (_window.width() < 768) {

			if (elem.hasClass('is-open')) {
				elem.removeClass('is-open');
				bodyContainer.removeClass('is-show');
			} else {
				elem.closest('.table').find('[table-body-js]').removeClass('is-show');
				elem.closest('.table').find('[table-head-js]').removeClass('is-open');

				elem.toggleClass('is-open');
				bodyContainer.toggleClass('is-show');
			}
		}
	});

	/**
   * Speaker list titles
   */
	$('[list-title-js]').on('click', function (e) {
		let elem = $(e.currentTarget),
			parentContainer = elem.closest('.list__row'),
			showContainer = elem.siblings('.list__row-wrapper');

		if (_window.width() < 767) {
			parentContainer.toggleClass('is-hide');
			showContainer.slideToggle(500);
		}
	});

	/**
   * "More From This" tabs
   */
	$('[individual-btn-js]').on('click', function (e) {
		let elem       = $(e.currentTarget),
			elemAttrId = elem.attr('data-name');

		$('[individual-btn-js]').removeClass('is-active');
		elem.addClass('is-active');

		$('[individual-tab-js]').addClass('d-none');
		$('#'+elemAttrId).removeClass('d-none');

	});

	/**
   * For myPlaylist.html (not implemented yet)
   */
	$('[playlist-btn-js]').on('click', function (e) {
		let elem = $(e.currentTarget),
			tableWrap = elem.closest('.playlist__row').find('.playlist__body');

		elem.toggleClass('is-active');
		tableWrap.slideToggle(300);
	});

	/**
   * Keyboard icon/button in search box
   */
	$('[search-keyboard-js]').on('click', function (e) {
		let elem = $(e.currentTarget),
			keyboard = elem.closest('[search-wrap-js]').find('.search__keyboard'),
			visibleKeyboard = $('.search__keyboard');

		if (_window.width() > 767) {

			if (visibleKeyboard.is(':visible')) {
				visibleKeyboard.closest('[search-wrap-js]').find('label').removeClass('is-active');
				visibleKeyboard.fadeOut(300);
			}

			elem.toggleClass('is-active');
			keyboard.fadeToggle(300);
		}
	});

	/**
   * Search results collapsable sections
   */
	$('.result__box-link').on('click', function (e) {
		let elem = $(e.currentTarget),
			resultDataWrap = elem.closest('.result__box').find('[result-body-js]');

		resultDataWrap.slideToggle(300);
	});

	/**
	 * Initialize select inputs
	 */
	function initSelectricJs() {
		$('select').selectric({
			responsive: true,
			disableOnMobile: false,
			expandToItemText: true,
			multiple: {
				maxLabelEntries: 1
			}
		}).on('change', function () {
			window.isFiltersChanged = true;
			let isNative = $(this).parent().hasClass('selectric-is-native');
			if ( isNative || !this.multiple ){ performFilterQuery(); }
		}).on('selectric-before-close', function () {
			if (!window.isFiltersChanged || !this.multiple ) { return; }
			window.isFiltersChanged = false;
			performFilterQuery();
		});
	}
	function performFilterQuery() {
		let filters = [];
		$('select[filter-select-js], input[filter-select-js]').each((index, el) => {
			let value = $(el).val();
			let name  = $(el).attr('filter-select-js');
			if (!isEmpty(value)) {
				value = join(concat([], value), ',').replace('/', '-');
				filters.push(name + '=' + value);
			}
		});
		window.location.search = '?' + filters.join('&');
	}
	initSelectricJs();

	/* Initialize date-picker */
	function initDatePicker() {
		$('.input-daterange').datepicker({
			startDate: '01/1950',
			format: 'mm/yyyy',
			endDate: '0d',
			startView: 2,
			minViewMode: 1,
			maxViewMode: 2,
			orientation: 'auto',
			autoclose: true,
			keepEmptyValues: true,
			clearBtn: true,
			todayHighlight: true,
			assumeNearbyYear: true
		}).on('changeDate', performFilterQuery);
	}
	initDatePicker();

	/**
   * Category listing: expand sub-categories
   */
	$('.mainc__block-head').on('click', function (e) {
		let elem = $(e.currentTarget),
			mainCategoryDataWrap = elem.closest('.mainc__block').find('[main-category-body-js]');

		mainCategoryDataWrap.slideToggle(300);
	});
	$('[main-category-btn-js]').on('click', function (e) {
		let elem = $(e.currentTarget);

		if (!elem.hasClass('is-active')) {
			elem.find('span').html('hide more');
		} else {
			elem.find('span').html('show more');
		}

		elem.toggleClass('is-active');
		$('[main-category-body-js]').toggleClass('mainc__block-body-collapsed');
	});
}
