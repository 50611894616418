document.addEventListener('DOMContentLoaded', function() {

	let music            = document.getElementById('music'), // id for audio element
		duration, // Duration of audio clip
		pButton          = document.getElementById('playButton'), // play button
		rewindButton     = document.getElementById('rewindButton'), // rewind button
		forwardButton    = document.getElementById('forwardButton'), // forward button
		speedButton      = document.getElementById('speedButton'), // speed button
		timeline         = document.getElementById('timeline'), // timeline
		mjhslider        = document.getElementById('mjhslider'), // slider
		mjhshiurduration = document.getElementById('mjhshiurduration'), // used for the current shiur position
		mjhshiurposition = document.getElementById('mjhshiurposition'); // used for the current shiur position

	if (!music) { return; }

	// timeline width adjusted for play-head
	let timelineWidth = timeline.offsetWidth;

	// play button event listener
	pButton.addEventListener('click', play);

	// timeupdate event listener
	music.addEventListener('timeupdate', timeUpdate, false);

	// makes timeline clickable
	timeline.addEventListener('click', function(event) {
		music.currentTime = duration * clickPercent(event);
	}, false);

	mjhslider.addEventListener('click', function (event) {
		music.currentTime = duration * clickPercent(event);
	}, false);

	rewindButton.addEventListener('click', function () {
		let newTime = music.currentTime - 30;
		music.currentTime = newTime > 0 ? newTime : 0;
	}, false);

	forwardButton.addEventListener('click', function () {
		let newTime = music.currentTime + 30;
		music.currentTime = newTime < duration ? newTime : duration;
	}, false);

	speedButton.addEventListener('click', function () {
		let speed = music.playbackRate;
		let speeds = [1.0, 1.2, 1.5, 1.7, 2.0, 0.8];
		let i = 1 + speeds.indexOf(speed);
		if (i === speeds.length) { i = 0; }
		music.playbackRate = speeds[i];
		speedButton.innerHTML = speeds[i].toString() + 'x';
	}, false);

	document.addEventListener('keydown', function (event) {
		if (event.code === 'Space') {
			event.preventDefault();
		}
	});
	document.addEventListener('keyup', function (event) {
		if (event.code === 'Space') {
			play();
		}
	});

	//used to change the button from play to pause on the autoplay
	music.onplay = function () {
		updatePlayStatus();
	};
	music.onpause = function () {
		updatePlayStatus();
	};
	music.onended = function () {
		updatePlayStatus();
	};

	// returns click as decimal (.77) of the total timelineWidth
	function clickPercent(event) {
		return (event.clientX - getPosition(timeline)) / timelineWidth;
	}

	/*	// mouseDown EventListener
	function mouseDown() {
		onplayhead = true;
		window.addEventListener('mousemove', moveplayhead, true);
		music.removeEventListener('timeupdate', timeUpdate, false);
	}

	// mouseUp EventListener
	// getting input from all mouse clicks
	function mouseUp(event) {
		if (onplayhead) {
			moveplayhead(event);
			window.removeEventListener('mousemove', moveplayhead, true);
			// change current time
			music.currentTime = duration * clickPercent(event);
			music.addEventListener('timeupdate', timeUpdate, false);
		}
		onplayhead = false;
	}*/

	// timeUpdate
	// Synchronizes playhead position with current point in audio
	function timeUpdate() {
		let playPercent = timelineWidth * (music.currentTime / duration);
		mjhshiurposition.innerHTML = formatTime(music.currentTime);
		mjhslider.style.width = playPercent + 'px';
		if (music.currentTime === duration) {
			pButton.className = '';
			pButton.className = 'player__control-btn play';
		}
	}

	function updatePlayStatus() {
		if (music.paused || music.ended) {
			pButton.className = '';
			pButton.className = 'player__control-btn play';
		} else {
			pButton.className = '';
			pButton.className = 'player__control-btn pause';
		}

		// Store the time in the local storage, along with the current audio src
		localStorage.setItem('audioSrc', music.currentSrc);
		localStorage.setItem('audioTime', music.currentTime);
	}

	//Play and Pause
	function play() {
		// start music
		if (music.paused || music.ended) {
			music.play();
		} else {
			music.pause();
		}
	}

	// Gets audio file duration
	music.addEventListener('canplaythrough', function () {
		duration = music.duration;
		mjhshiurduration.innerHTML = formatTime(duration);
	}, false);


	function formatTime(seconds) {
		let minutes = Math.floor(seconds / 60);
		minutes = (minutes >= 10) ? minutes : '0' + minutes;
		seconds = Math.floor(seconds % 60);
		seconds = (seconds >= 10) ? seconds : '0' + seconds;
		return minutes + ':' + seconds;
	}

	// getPosition
	// Returns elements left position relative to top-left of viewport
	function getPosition(el) {
		return el.getBoundingClientRect().left;
	}

	// Restore the time from the local storage, if the audio src is the same
	if (music.currentSrc === localStorage.getItem('audioSrc')) {
		music.currentTime = localStorage.getItem('audioTime');
	}

/* DOMContentLoaded*/
});

