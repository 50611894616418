import Alpine from 'alpinejs';
import Persist from '@alpinejs/persist';

document.addEventListener('DOMContentLoaded', () => {
	Alpine.plugin(Persist);
	window.Alpine = Alpine;  // Helpful for debugging
	Alpine.store('nirc', {
		// Playlist data
		playlists: Alpine.$persist([]).as('playlists'),

		addToPlaylist(playlist_title, target) {
			const playlist_index = this.playlists.findIndex(playlist => playlist.title === playlist_title);
			const shiur = Object.assign({}, target.dataset);

			// If the shiur is already in the playlist, remove it
			const shiur_index = this.playlists[playlist_index].shiurim.findIndex(s => s.id === shiur.id);
			if (shiur_index !== -1) {
				this.playlists[playlist_index].shiurim.splice(shiur_index, 1);
			} else {
				this.playlists[playlist_index].shiurim.push(shiur);
			}
		},
		changeOrderInPlaylist(playlist_title, shiur_id, delta) {
			let list = this.playlists.find(playlist => playlist.title === playlist_title).shiurim;
			const index = list.findIndex(s => s.id === shiur_id);
			const newIndex = index + delta;
			if (newIndex < 0 || newIndex >= list.length) {
				return;
			}
			const [shiur] = list.splice(index, 1);
			list.splice(newIndex, 0, shiur);
		},

		// Playlist functions
		addPlaylist(target=null) {
			const title = prompt('Enter a title for the new playlist:');
			if (!title) {
				return;
			}
			this.playlists.push({
				title: title,
				shiurim: []
			});
			if (target) {
				this.addToPlaylist(title, target);
			}
		},
		renamePlaylist(playlist_title) {
			const new_title = prompt('Enter a new title for the playlist:');
			if (!new_title) {
				return;
			}
			const playlist_index = this.playlists.findIndex(playlist => playlist.title === playlist_title);
			this.playlists[playlist_index].title = new_title;
		},
		removePlaylist(playlist_title) {
			const playlist_index = this.playlists.findIndex(playlist => playlist.title === playlist_title);
			if (confirm(`Are you sure you want to delete the playlist "${playlist_title}"?`)) {
				this.playlists.splice(playlist_index, 1);
			}
		},

		// Playback functions
		playPlaylist(playlist_title) {
			const playlist_index = this.playlists.findIndex(playlist => playlist.title === playlist_title);
			// todo - play the first shiur in the playlist in popup
		},
		playShiur(shiur) {
			// Play the shiur in popup
			window.open(shiur.listen_link, 'new_window', 'width=500,height=600,left=50,top=50,scrollbars=0');
		},
		removeFromPlaylist(playlist_title, shiur_id) {
			const playlist_index = this.playlists.findIndex(playlist => playlist.title === playlist_title);
			const shiur_index = this.playlists[playlist_index].shiurim.findIndex(s => s.id === shiur_id);
			this.playlists[playlist_index].shiurim.splice(shiur_index, 1);
		}
	});
	Alpine.start();
});

// Listen for changes to localStorage and update the Alpine data accordingly
window.addEventListener('storage', (event) => {
	// Only update if the change was made by a different tab and has changed
	let new_value = JSON.parse(event.newValue);
	if (event.key === 'playlists' && new_value !== Alpine.store('nirc').playlists) {
		Alpine.store('nirc').playlists = new_value;
	}
});
