(function($) {
	function objectToPairs(obj) {
		let str = '', sep = '';
		for (let p in obj) {
			if (obj.hasOwnProperty(p)) {
				str += sep + p + '=' + obj[p];
				sep = ',';
			}
		}
		return str;
	}

	$.fn.popupRight = function(options) {
		options = $.extend({}, $.fn.popupRight.defaults, options);

		// Move the window name out of the options array
		var optionWindowName = options.windowName || 'new_window';
		delete options.windowName;

		return this.each( function() {
			var windowName = $(this).data('popupName') || $(this).attr('target') || optionWindowName;
			var dataOptions = $(this).data('popupOptions') || {};
			var windowOptions = $.extend({}, options, dataOptions);

			//Position stuff...
			if (isNaN(windowOptions.top)) {
				windowOptions.top = parseInt((screen.height / 2) - (windowOptions.height / 2));
			}
			if (isNaN(windowOptions.left)) {
				windowOptions.top = parseInt((screen.width / 2) - (windowOptions.width / 2));
			}

			var optionsString = objectToPairs(windowOptions);

			$(this).click(function(event) {
				event.preventDefault();

				var windowObject = window.open(this.href, windowName, optionsString) ;

				// Save the window object for other code to use
				$(this).data('windowObject', windowObject);
			});
		});
	};

	$.fn.popupRight.defaults = {
		'width':       800,   // Window width
		'height':      600,   // Window height
		// "NaN" means we will calculate centering the popup
		'top':         NaN,     // Y offset (in pixels) from top of screen
		'left':        NaN,     // X offset (in pixels) from left side of screen
		'directories': 'no',    // Show directories/Links bar?
		'location':    'no',    // Show location/address bar?
		'resizeable':  'yes',   // Make the window resizable?
		'menubar':     'no',    // Show the menu bar?
		'toolbar':     'no',    // Show the tool (Back button etc.) bar?
		'scrollbars':  'yes',   // Show scrollbars?
		'status':      'no'     // Show the status bar?
	};

	$('.popup-right').popupRight();
})(window.jQuery);
